import React from 'react'
import { graphql } from 'gatsby'

import Insights from '../../../components/marketplace/insights'
import MarketplaceLayout from '../../../components/marketplace/MarketplaceLayout'

export default class InsightsPage extends React.Component {
  render() {
    const { data } = this.props
    const imagesByReportId = data.allFile.edges.reduce(
      (acc, { node: { fields, childImageSharp } }) => ({
        ...acc,
        [fields.report]: childImageSharp,
      }),
      {}
    )
    const reports = data.allReport.edges.map(({ node }) => ({
      ...node,
      previewImage: imagesByReportId[node._id]
        ? {
            image: { childImageSharp: imagesByReportId[node._id] },
          }
        : node.previewImage,
    }))
    return (
      <MarketplaceLayout active="insights">
        <Insights reports={reports} />
      </MarketplaceLayout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allReport {
      edges {
        node {
          title
          price
          previewImage
          details
          tags
          _id
        }
      }
    }
    allFile(filter: { fields: { reportImage: { eq: "true" } } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1024, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
          fields {
            reportImage
            report
          }
        }
      }
    }
  }
`
